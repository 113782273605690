const EDU_DATA = [
        {
          title: 'WWV Educational Consultants',
          imageUrl: 'education-banner.jpg',
          id: 1,
          size:'full',
          linkUrl: 'https://wwvpk.com/educationalconsultant/',
          description: 'Now study at your dream university and enjoy the freedom of education with the lowest fee structures, great scholarships and perfect ambience.'
        },
        {
          title: 'WWV Management Consultants',
          imageUrl: 'management-banner.jpg',
          id: 3,
          size:'full',
          linkUrl: 'https://wwvpk.com/managementconsultant',
          description: 'Discuss and follow the best industry practices to resolve issues in your organization. '
        }
    ];

export default EDU_DATA;
