import React from 'react';

import MenuItem from '../menu-item/menu-item.component';
import EDU_DATA from './edu.data';

import './edu.style.scss';

const EducationDirectory = ()=>(
  <div className='directory-menu'>
        {EDU_DATA.map(({ id, ...otherSectionProps }) => (
          <MenuItem key={id} {...otherSectionProps} />
        ))}
      </div>
);
export default EducationDirectory;