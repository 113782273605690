const HOME_DATA = [
        {
          title: 'BOSCH Building Management Systems',
          imageUrl: 'building-banner.jpg',
          id: 1,
          size:'small',
          linkUrl: 'https://wwvpk.com/',
          description: '"Protecting lives, buildings and assets is our aim, Building a safer and more secure world with effective communication."'
        },
        {
          logo: 'main-logo.png',
          size:'large',
          id: 2,
          linkUrl:'http://wwvgroup.com.pk/'
        },
        {
          title: 'Bosh Home Appliances',
          imageUrl: 'bosch-banner.jpg',
          id: 3,
          size:'small',
          linkUrl: 'http://boschpk.com/',
          description: '“Our products and services are designed to spark enthusiasm, improve quality of life, and help conserve natural resources. We want to deliver top quality and reliable Appliances. In short: we want to provide technology that is “Invented for life.”'
        },
        {
          title: 'WWV EXPORTS',
          imageUrl: 'exports-banner.jpg',
          size: 'small',
          id: 4,
          linkUrl: 'http://wwvexports.com/',
          description: '“No compromise on quality”'
        },
        {
          title: 'WWV Logistics',
          imageUrl: 'logistics-banner.jpg',
          size: 'small',
          id: 5,
          linkUrl: 'http://wwvlogistics.com/',
          description: '“Our Logistics management implements an SOP to ensure efficient, effective flow of goods, between the point of origin and the point of Destination in order to meet customers’ requirements.”'
        },
        {
          title: 'WWV HEALTH CARE SOLUTIONS',
          imageUrl: 'healthcare-banner.jpg',
          size: 'small',
          id: 5,
          linkUrl: 'https://wwvpk.com/healthcare/',
          description: '“In the recent Pandemic outbreak of coronavirus SARS-CoV-2, World Wide Vision  has  immediately launched Bosch Vivalytic and other rapid test kits in Pakistan  to help counter the increasing cases by providing solutions  which give test results in 2.5 hours.“'
        },
        {
          title: 'Corporate Social Response',
          imageUrl: 'cdfp-banner.jpg',
          size: 'small',
          id: 5,
          linkUrl: 'http://cdfp.org.pk/',
          description: '“Sharing our resources with deserving communities”'
        }
    ];

export default HOME_DATA;
