import React from 'react';
import { withRouter } from 'react-router-dom';

import './menu-item.style.scss';

const MenuItem = ({ title, imageUrl, size, history, linkUrl, match,logo,description }) => (
  <div
    className={`${size} menu-item`}
    onClick={() => window.open(`${linkUrl}`)}
  >
    <div
      className='background-image'
      style={{
        backgroundImage: `url(${imageUrl})`
      }}
    >
    {
      logo?<img className='logo' src={`${logo}`} />:null
    }
    </div>
    <div className='content'>
      <h1 className='title'>{title?title.toUpperCase():null}</h1>
      <span className='description'>{description?description:null}</span>
    </div>
  </div>
);

export default withRouter(MenuItem);