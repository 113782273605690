import React from 'react';
import EducationDirectory from '../../components/consultants/edu.component';

import './consultants.style.scss';

const EducationPage = () => (
  <div className='educationPage'>
      <EducationDirectory />
  </div>
);

export default EducationPage;