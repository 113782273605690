import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from './pages/homepage/homepage.component';
import EducationPage from './pages/consultants/consultants.component';

const App = ()=>{
        return (
    <div className='content'>
      <div className='page-right-content'>
        <Switch>
           <Route exact path='/' component={HomePage} />
           <Route exact path='/consultants' component={EducationPage} />
        </Switch>
      </div>
    </div>
  );
}

export default App;