import React from 'react';

import MenuItem from '../menu-item/menu-item.component';
import HOME_DATA from './home.data';

import './directory.style.scss';

const Directory = ()=>(
  <div className='directory-menu'>
        {HOME_DATA.map(({ id, ...otherSectionProps }) => (
          <MenuItem key={id} {...otherSectionProps} />
        ))}
      </div>
);
export default Directory;